export default {
    mounted(el,binding) {
        //dragenter', 'dragover', 'dragleave', 'drop'
      /*  console.log(binding)
        el.addEventListener('drop', e=>{
            console.log(e.dataTransfer.files);
            e.target.classList.remove("dropzone");
            e.preventDefault();
        });*/
        el.addEventListener('dragover', e=>{
            e.preventDefault();
        });
        el.addEventListener('dragleave', e=>{
             e.target.classList.remove("dropzone");
            e.preventDefault();
        });
        el.addEventListener('dragenter', e=>{
           e.target.classList.add("dropzone");
            e.preventDefault();
        });

    },

    updated(el) {
      
       
    },
}